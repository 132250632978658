
































import { Vue, Component, Prop } from "vue-property-decorator";
import adminService from "@/services/AdminService";
import { OrganisationDetails } from "@/models/OrganisationDetails";
import { organisationModule } from "@/store/modules/OrganisationModule";

@Component
export default class ImportHistory extends Vue {
  public importBatches: any = [];

  private get organisation(): OrganisationDetails {
    return organisationModule.organisation;
  }

  private async getImports() {
    this.importBatches = await adminService.getImports(this.organisation.id);
  }

  public async deleteImport(id: string) {
    await adminService.deleteImport(this.organisation.id, id);
    await this.getImports();
  }

  public async reImport(id: string) {
    await adminService.reImport(this.organisation.id, id);
  }  

  public async viewInvoices(id: string) {
    this.$router.push(`/imports/${id}/`);
  }

  public async activated() {
    await this.getImports();
  }
}
